<template lang='pug'>
	.d-flex.flex-column.main-new(:class="{'h-100' : isAvailable == true}")
		router-view(@available="d => isAvailable = d")
</template>

<script>
export default {
	data: () => ({
		isAvailable: false,
	}),
	beforeMount() {
		document.body.classList.add("background-main");
	},
	destroyed() {
		document.body.classList.remove("background-main");
	},
}
</script>

<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.main-new {
	margin: 0 auto;
	min-height: 100%;
	height: auto;
}
</style>
